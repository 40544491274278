<template>
  <div
    :ref="reference"
    class="welcome__illustration animated"
    :class="reference"
    @click="handleClick" />
</template>

<script>
import lottie from 'lottie-web';
export default {
  name: 'LottieAnimation',
  props: {
    reference: {
      type: String,
      default: ''
    },
    animationName: {
      type: String,
      default: ''
    },
    loop: Boolean
  },
  mounted() {
    this.addIcon();
  },
  updated() {
    this.$refs[this.reference].innerHTML = '';
    this.addIcon();
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    },
    addIcon() {
      this.anim = lottie.loadAnimation({
        container: this.$refs[this.reference],
        renderer: 'svg',
        loop: this.loop,
        autoplay: true,
        animationData: require(`@/assets/images/animated/${this.animationName}.json`)
      });
    }
  }
};
</script>